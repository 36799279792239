export const settings = {
    video: {
        videoSeekTime: 0.5,
        selector: 'video',
    },
    searchForm: {
        formSelector: '.search-form',
        inputSelector: '.search-form__input-container__search',
        templateSelector: '#keyboard-template',
        operatingSystemSelector: '#os',
        itemsSelector: '.slider-section--course .slider__slide, .grid-card .card--course',
        hiddenClass: 'visually-hidden',
        showClass: 'card--show',
    },
    filter: {
        formSelector: '#filter-form',
        itemSelector: '.slider-section--course .slider__slide, .grid-card .card--course',
        linksSelector: 'a.categories__category__link',
        showClass: 'card--show',
        hiddenClass: 'visually-hidden',
        categoryInputSelector: '.slider--cat input[type="checkbox"]',
        tagInputSelector: '.tag-filter input[type="checkbox"]',
    },
    goUpBtn: {
        scrollTrigger: 300,
        selector: '.btn--go-up',
        showClass: 'btn--go-up--visible',
        inverseClass: 'btn--secondary',
    },
    sliderCat: {
        sliderSelector: '.categories .slider',
        navigationSelector: '.categories .slider-navigation',
        prevButtonSelector: '.categories .slider-navigation__btn--prev',
        nextButtonSelector: '.categories .slider-navigation__btn--next',
    },
    sliderNews: {
        sliderSelector: '.slider-section--news .slider',
        navigationSelector: '.slider-section--news .slider-navigation',
        prevButtonSelector: '.slider-section--news .slider-navigation__btn--prev',
        nextButtonSelector: '.slider-section--news .slider-navigation__btn--next',
    }

};
