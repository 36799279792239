export type SliderCatSettings = {
    sliderSelector: string;
    navigationSelector: string;
    prevButtonSelector: string;
    nextButtonSelector: string;
};

export class Slider {
    private sliderElement: HTMLElement;
    private navigationElement: HTMLElement;
    private prevButton: HTMLElement;
    private nextButton: HTMLElement;

    constructor(settings: SliderCatSettings) {
        this.sliderElement = document.querySelector(settings.sliderSelector);
        this.navigationElement = document.querySelector(settings.navigationSelector);
        this.prevButton = document.querySelector(settings.prevButtonSelector);
        this.nextButton = document.querySelector(settings.nextButtonSelector);
        if (this.isTouchScreen()) {
            this.hideNavigationIfTouchScreen();
        } else {
            this.nextButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.nextSlide();
            });
            this.prevButton.addEventListener('click', (e) => {
                e.preventDefault();
                this.prevSlide();
            });
        }
    }

    isTouchScreen() {
        return ('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            // @ts-ignore
            (navigator.msMaxTouchPoints > 0);

    }

    private nextSlide() {
        this.sliderElement.scrollLeft += this.sliderElement.offsetWidth;
    }

    private prevSlide() {
        this.sliderElement.scrollLeft -= this.sliderElement.offsetWidth;
    }

    private hideNavigationIfTouchScreen() {
        this.navigationElement.style.display = 'none';
    }
}
